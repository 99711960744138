import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Container, Card, Flex, Box, Link, Message } from 'theme-ui';
import { PageStructure } from '../../components/help-center/PageStructure';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageStructure layoutProps={props} title="Account Setup" prev={{
  name: 'Getting Started',
  path: '/getting-started'
}} next={{
  name: 'Creating an Account',
  path: '/creating-an-account'
}}>
        {props.children}
    </PageStructure>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The account setup flow for Simple Review Management is broken up into two smaller tasks:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/help-center/creating-an-account"
        }}>{`Creating a user account`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/help-center/creating-a-company"
        }}>{`Creating a company`}</a>{` in the user account`}</li>
    </ul>
    <Message sx={{
      mb: 4
    }} mdxType="Message">
If you use QuickBooks Online and you intend to connect your QuickBooks Online account to your Simple RM account, you can follow a <Link href="/help-center/account-creation-with-quickbooks-online/" mdxType="Link">simplified account setup flow</Link>.
    </Message>
    <p>{`When you are done setting up your account Simple RM will perform a quick `}<a parentName="p" {...{
        "href": "/help-center/account-initialization-process"
      }}>{`account initialization process`}</a>{` and then you will be automatically redirected to your `}<a parentName="p" {...{
        "href": "/help-center/account-dashboard-intro"
      }}>{`account dashboard`}</a>{`.`}</p>
    <p>{`For now, move on to the next article `}<a parentName="p" {...{
        "href": "/help-center/creating-an-account"
      }}>{`Creating an Account`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      